.map-wrapper {
  max-width: 1080px;
  margin: 60px auto;
  height: 250px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}

.logos {
  display: flex;
  justify-content:  center;
  align-items: center;
  width: 100%;
}

.map {
  width: 400px;
}

.text-wrapper {
  width: 500px;
  margin-right: 30px;
} 

.text {
  font-size: 1rem;
  font-family: 'Circular Std';
  letter-spacing: 0.08vw;
  line-height: 25px;
} 

.title {
  font-size: 2rem;
	text-align: center;
  font-family: 'Circular Bold';
  margin-bottom: 30px;
}

.commune {
  width: 160px;
}

.laurier {
  width: 210px;
}

.logo {
  width: 140px;
}


@media (max-width: 992px) {
  .pres {
    margin: 30px auto;
  }

  .title {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }

  .text {
    padding: 10px;
    text-align: center;
  }

  .map-wrapper {
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 500px;
    padding: 0px;
  }

  .text-wrapper {
    width: 100%;
    margin-right: 30px;
  }

  .map {
    margin-top: 0px;
    width: 100%;
    height: 250px;
  }
}