body, html {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	height: 100%;
	width: 100%;
}

button {
	border: none;
	width: auto;
	overflow: visible;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;
	outline: none;
}

a {
	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
}
