.contacts {
	max-width: 1080px;
	margin: 50px auto;
}

.title {
	margin-bottom: 50px;
	font-size: 2rem;
	text-align: center;
	font-family: 'Circular Bold';
}

.wrapper {
	-webkit-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
	-moz-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
	box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
}

.header {
	position: relative;
}

.profilePhoto {
	position: absolute;
	bottom: -95px;
	width: 150px;
	padding: 3px;
	border-radius: 50%;
	margin: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.profile-wrapper {
	margin-top: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.name {
	margin-bottom: 10px;
	font-size: 1.7rem;
	text-align: center;
	font-family: 'Circular Bold';
}

.diplome {
	font-size: 1rem;
	font-family: 'Circular Std';
	line-height: 2vw;
	letter-spacing: 0.08vw;
	margin-bottom: 30px;
}

.infos-wrapper {
	display: flex;
	width: 100%;
	justify-content: space-around;
	padding-bottom: 40px;
}

.infos-box {
	padding: 20px;
	width: 450px;
	-webkit-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
	-moz-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
	box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
}

.icon-subject {
	display: flex;
	padding: 10px;
}

.icons-right {
	margin-right: 20px;
	margin-top: 8px;
}

.type-right {
	font-size: 0.6em;
	margin-bottom: 3px;
	font-family: 'Circular Std';
}

.info-right {
	font-family: 'Circular Std';
	font-size: 1.1em;
	margin-bottom: 2px;
}


@media (max-width: 992px) {
  .title {
		font-size: 1.3rem;
		font-weight: bold;
	}
	
	.name {
		font-size: 1.4rem;
	}

	.diplome {
		font-size: 0.9rem;
	}

	.contacts {
		max-width: 1080px;
		margin: 30px auto;
		padding: 0px;
	}

	.info-right {
		font-size: 1em;
	}

	.infos-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: space-around;
		padding-bottom: 0px;
		height: 100%;
	}

	.infos-box {
		padding: 5%;	
		width: 80%;
		margin: 5% 5% 0% 5%;
		-webkit-box-shadow: 0px 7px 10px -6px rgba(0,0,0,0.30);
		-moz-box-shadow: 0px 7px 10px -6px rgba(0,0,0,0.30);
		box-shadow: 0px 7px 10px -6px rgba(0,0,0,0.30);
	}

	.wrapper {
		-webkit-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.0);
		-moz-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.0);
		box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.0);
	}

	.icon-subject {
		display: flex;
		padding: 5px;
	}

	.center-cropped {
		width: 100%;
		height: 200px;
		position: relative;
		overflow: hidden;
	}


}

@media (min-width: 992px) {
		.center-cropped {
			width: 100%;
			height: 200px;
			position: relative;
		}
		
		.img {
			width: 100%;
		}
}