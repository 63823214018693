@media (min-width: 992px) {
  .activity-wrapper {
  padding: 0 20px;
  }
}

.line {
	width: 40%;
	margin-top: 50px;
	margin-bottom: 50px;
}