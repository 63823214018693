.wrapper {
	margin: 30px auto;
	max-width: 1080px;
	padding: 20px;
}

.main-title {
	font-size: 2rem;
	text-align: center;
	font-family: 'Circular Bold';
	margin-bottom: 70px;
}

.blocks {
	display: flex;
	width: 100%;
	justify-content: space-around;
}

.block {
	padding: 30px;
	margin: 10px;
	width: 250px;
	-webkit-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
	-moz-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
	box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.30);
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: flex;
	flex-direction: column;
	border-radius: 3px;
	align-items: center;
}

.block:hover {
	-webkit-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.50);
	-moz-box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.50);
	box-shadow: 0px 7px 20px -6px rgba(0,0,0,0.50);
}

.title {
	font-size: 1.2rem;
	font-family: 'Circular Bold';
	text-align: center;
}

.subtitle {
	font-size: 1rem;
	font-family: 'Circular Std';
	color: #333333;
	margin-top: 5px;
	text-align: center;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	color: #323232;
}

@media (max-width: 700px) {
	.wrapper {
		margin: 10px auto;
		padding: 0px;
	}

	.blocks {
		flex-direction: column;
		align-items: center;
	}

	.block {
		margin-bottom: 20px;
		padding: 20px 30px 30px 30px;
		-webkit-box-shadow: 0px 7px 10px -6px rgba(0,0,0,0.30);
		-moz-box-shadow: 0px 7px 10px -6px rgba(0,0,0,0.30);
		box-shadow: 0px 7px 10px -6px rgba(0,0,0,0.30);
	}

	.main-title {
		margin-top: 50px;
		margin-bottom: 40px;
	}
}

@media (min-width: 992px) {
	.block {
		margin-bottom: 20px;
		padding: 20px 30px 30px 30px;
		height: 250px;
	}
}
