.map-wrapper {
  margin: 20px;
}

.map {
  width: 200px;
  height: 200px;
}


@media (max-width: 992px) {
  .map {
    width: 100%;
    height: 200px;
  }
}