.sidenav {
	z-index: 1000;
	height: 60px;
	width: 230px;
	background-color: #191919;
	height: 100vh;
	position: fixed;
	display: flex;
	flex-direction: column;
	font-family: 'Amatic SC', cursive;
}

.image {
	width: 100%;
}

.link {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 70px;
	border-bottom: 1px solid rgba(48, 31, 31, 0.05);
	cursor: pointer;
	color: white;
}

.link:hover {
	background-color: #333;
	color: #fff;
}

.link-name {
	margin-left: 20px;
	font-size: 1.5rem;
}

.accueil {
	color: white;
	font-size: 1.8em;
	display: flex;
	align-items: center;
	background-color: #232323;
	height: 100px;
}

.accueil:hover {
	background-color: #333;
}

.logo {
	width: 65px;
	margin-left: 20px;
	margin-right: 20px;
}