.description {
	max-width: 1080px;
	padding: 40px;
	margin: 0 auto;
}

.title {
	font-size: 2rem;
	text-align: center;
	font-family: 'Circular Bold';
	margin-bottom: 50px;
}

.text {
	font-size: 1rem;
	font-family: 'Circular Std';
	line-height: 2vw;
	letter-spacing: 0.08vw;
}

.link {
	text-decoration: underline;
	font-size: 1rem;
}

@media (max-width: 992px) {
	.description {
		max-width: 1080px;
		padding: 30px;
		margin: 65px auto 0 auto;
	}

	.text {
		line-height: 20px;
	}

	.title {
		margin: 0 0 30px 0;
		font-size: 1.7rem;
	}
}
