.page-wrapper {
	display: flex;
}

.main-wrapper {
	width: 100%;
	margin: 0 auto;
}

.desktop-header {
	width: 100%;
	-webkit-box-shadow: 0px 7px 1px -6px rgba(0,0,0,0.10);
	-moz-box-shadow: 0px 7px 1px -6px rgba(0,0,0,0.10);
	box-shadow: 0px 7px 1px -6px rgba(0,0,0,0.10);
}

.logos {
  display: flex;
  justify-content:  space-between;
  align-items: center;
	padding-left: 50px;
	padding-right: 50px;
	height: 170px;
	font-family: 'Amatic SC', cursive;
	font-size: 3.7em;
	
}

.logos-right {
	display: flex;
	align-items: center;
}

.commune {
	width: 160px;
}

.laurier {
	width: 170px;
	margin-right: 30px;
}

.logo {
	width: 120px;
}

.station-verte {
	width: 65px;
}

.title-wrapper {
	display: flex;
	align-items: center;
	width: 67%;
	justify-content: space-around;
}

.title {
	border: 2px solid #232323;
	padding: 5px 25px;
	border-radius: 5px;
}

@media (max-width: 992px) {
	.page-wrapper {
		padding-left: 20px;
		padding-right: 20px;
		margin-top: 65px;
	}
}
