.sectionFooter {
  height: 50px;
  background-color: #32383e;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  font-size: 10px
}

.footerMade {
  color: #a1aab4;
  margin-right: 0.4vw;
}

.footerName {
  color: #fff;
  text-decoration: none;
}

.footerName:hover {
  color: #fae951;
}

.footerHeart {
  width: 10px;
  margin-right: 0.4vw;
}