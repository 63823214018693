.center-cropped {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.img {
  user-select: none;
  position: absolute;
  margin: auto; 
  min-height: 100%;
  min-width: 100%;

  left: -10%;
  right: -10%;
  top: -100%;
  bottom: -100%;
}

@media (max-width: 992px) {
  .center-cropped {
    width: 100%;
    height: 80px;
    overflow: hidden;
    position: relative;
  }

  .img {
    user-select: none;
    position: relative;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}